import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Layout from "../components/Layout"
import useStyles from '../styles/portfolioStyles'
import { GridList, GridListTile, Hidden, Grid } from '@material-ui/core'
import PortfolioItem from '../components/PortfolioItem'
import PageTitle from '../components/PageTitle'
import SEO from '../components/seo'

const Portfolio = () => {

  const classes = useStyles()
  const query = useStaticQuery(graphql`
{
  allFile(filter: {childMarkdownRemark: {frontmatter: {id: {eq: "portfolio"}}}}) {
    nodes {
      childMarkdownRemark {
        frontmatter {
          title
          seoDescription
          seoKeywords
          seoTitle
          portfolio {
            img {
              childImageSharp {
                fluid {
                  base64
                  tracedSVG
                  srcWebp
                  srcSetWebp
                  originalImg
                  originalName
                }
              }
            }, 
            description
            id
            subtitle
            title
            cols
            rows
          }
        }
      }
    }
  }
}
`)

  const portfolioItems = query.allFile.nodes[0].childMarkdownRemark.frontmatter.portfolio
  const pageTitle = query.allFile.nodes[0].childMarkdownRemark.frontmatter.title
  const seoDescription = query?.allFile.nodes[0].childMarkdownRemark.frontmatter.seoDescription
  const seoKeywords = query?.allFile.nodes[0].childMarkdownRemark.frontmatter.seoKeywords
  const seoTitle = query?.allFile.nodes[0].childMarkdownRemark.frontmatter.seoTitle

  return (
    <Layout>
      <SEO title={seoTitle !== undefined ? seoTitle : pageTitle} description={seoDescription !== undefined ? seoDescription : null} keywords={seoKeywords !== undefined ? seoKeywords : null}/>
      <PageTitle title={pageTitle} />
      <div className={classes.root}>
        <Hidden smDown>
          <GridList cellHeight='auto' className={classes.gridList} cols={12} >
            {portfolioItems.map((item) => (
              <GridListTile key={item.id} cols={item.cols} className={classes.item}>
                <PortfolioItem item={item} />
              </GridListTile>
            ))}
          </GridList>
        </Hidden>
        <Hidden mdUp>
          <Grid container spacing={4}>
            {portfolioItems.map((item) => (
              <Grid item xs={12} sm={12} key={item.id} >
                <div className={classes.item}>
                  <PortfolioItem item={item} />
                </div>
              </Grid>
            ))}
          </Grid>
        </Hidden>

      </div>
    </Layout>
  )
}
export default Portfolio
