import React from "react"
import useStyles from './styles'
import { Link } from "gatsby"


const PortfolioItem = ({ item }) => {
  const classes = useStyles()

  const icon = item?.img.childImageSharp.fluid.originalImg
  const title = item?.title
  const subtitle = item?.subtitle
  const id = item?.id
  const description = item?.description
  return (
    <>
      {description ?
        <Link to={`/portfolio-item-page?id=${id}`} className={classes.link}>
          <div className={classes.itemContent}>
            <div>
              <img src={icon} alt={subtitle} title={title} className={classes.image} />
            </div>
            <h3>{title}</h3>
            <p>{subtitle}</p>
          </div>
        </Link>
        :
        <div className={classes.itemContent}>
          <div>
          <img src={icon} alt={subtitle} title={title} className={classes.image} />
          </div>
          <h3>{title}</h3>
          <p>{subtitle}</p>
        </div>
      }
    </>
  )
}

export default PortfolioItem